/* eslint-disable check-file/folder-naming-convention */
import dynamic from 'next/dynamic';

export const ActivitySelectorV1 = dynamic(() => import(/* webpackChunkName: "ActivitySelectorV1" */'@/features/activity-selector-v1/activity-selector-v1'));
export const FilterGroupDesktopV1 = dynamic(() => import(/* webpackChunkName: "FilterGroupDesktopV1" */'@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1'));
export const ClimateDiagramsV2Desktop = dynamic(() => import(/* webpackChunkName: "ClimateDiagramsV2Desktop" */'@/features/climate-diagrams-v2/climate-diagrams-v2-desktop/climate-diagrams-v2-desktop'));
export const WindyTileV1Desktop = dynamic(() => import(/* webpackChunkName: "WindyTileV1Desktop" */'@/features/windy-tile-v1/windy-tile-desktop/windy-tile-desktop'));
export const ActivityTilesV1Desktop = dynamic(() => import(/* webpackChunkName: "ActivityTilesV1Desktop" */'@/features/activity-tiles/activity-tiles-v1-desktop/activity-tiles-v1-desktop'));
export const TrustDesktopV1 = dynamic(() => import(/* webpackChunkName: "TrustDesktopV1" */'@/features/trust/trust-desktop-v1/trust-component-switch-desktop/trust-component-switch-desktop'));
export const FactCheckDesktopV3 = dynamic(() => import(/* webpackChunkName: "FactCheckDesktopV3" */'@/features/fact-check-desktop-v3/fact-check-desktop-v3'));
export const OtherActivitiesV1Desktop = dynamic(() => import(/* webpackChunkName: "OtherActivitiesV1Desktop" */'@/features/other-activities-v1/other-activities-v1-desktop/other-activities-v1-desktop'));
export const TippV1Desktop = dynamic(() => import(/* webpackChunkName: "TippV1Desktop" */'@/features/tipp-v1/tipp-v1-desktop'));
export const KeyFactsV2Desktop = dynamic(() => import(/* webpackChunkName: "KeyFactsV2Desktop" */'@/features/key-facts-v2/key-facts-v2-desktop'));
export const SchoolHeaderV1Desktop = dynamic(() => import (/* webpackChunkName: "SchoolHeaderV1Desktop" */'@/features/school-header-v1/school-header-v1-desktop/school-header-v1-desktop'));
export const SchoolProductHeaderV1Desktop = dynamic(() => import (/* webpackChunkName: "SchoolProductHeaderDesktopV1" */'@/features/school-product-header-v1/school-product-header-v1-desktop'));
export const SchoolProductSwiperV1Desktop = dynamic(() => import (/* webpackChunkName: "SchoolProductSwiperV1Desktop" */'@/features/school-product-swiper-v1/school-product-swiper-v1-desktop/school-product-swiper-v1-desktop'));
export const SchoolProductEventSwiperV1Desktop = dynamic(() => import (/* webpackChunkName: "SchoolProductEventSwiperV1Desktop" */'@/features/school-product-event-swiper/school-product-event-swiper-v1-desktop/school-product-event-swiper-v1-desktop'));
export const SchoolProductTilesV2Desktop = dynamic(() => import(/* webpackChunkName: "SchoolProductTilesV2Desktop" */'@/features/school-product-tiles-v2/school-product-tiles-v2-desktop/school-product-tiles-v2-desktop'));
export const SchoolTilesV2Desktop = dynamic(() => import(/* webpackChunkName: "SchoolTilesV2Desktop" */'@/features/schools/school-tiles-v2-desktop/school-tiles-v2-desktop'));
export const SpotSelectorV2Desktop = dynamic(() => import(/* webpackChunkName: "SpotSelectorV2Desktop" */'@/features/spot-selector-v2/spot-selector-v2-desktop/spot-selector-v2-desktop'));
export const SortDesktopV1 = dynamic(() => import(/* webpackChunkName: "SortDesktopV1" */'@/features/sort-desktop-v1/sort-desktop-v1-container'));
export const GalleryV1Desktop = dynamic(() => import(/* webpackChunkName: "GalleryV1Desktop" */'@/features/gallery-v1/gallery-desktop/gallery-v1-desktop'));
export const InfoBoxV1Desktop = dynamic(() => import(/* webpackChunkName: "InfoBoxV1Desktop" */'@/features/info-box-v1/info-box-desktop/info-box-v1-desktop'));
export const TextBannerDesktopV1 = dynamic(() => import(/* webpackChunkName: "TextBannerDesktopV1" */'@/features/banner/text-banner-desktop-v1/text-banner-desktop-v1'));
export const SpotHeaderDesktopV1 = dynamic(() => import(/* webpackChunkName: "SpotHeaderDesktopV1" */'@/features/spot-header/spot-header-desktop-v1/spot-header-desktop-v1'));
export const LandmarkTilesV1Desktop = dynamic(() => import(/* webpackChunkName: "LandmarkTilesV1Desktop" */'@/features/landmark-tiles/landmark-tiles-v1-desktop/landmark-tiles-v1-desktop'));
export const PlacesV2Desktop = dynamic(() => import(/* webpackChunkName: "PlacesDesktopV2" */'@/features/places/places-v2-desktop/places-v2-desktop'));
export const SpotResultsDesktopV4 = dynamic(() => import(/* webpackChunkName: "SpotResultsDesktopV4" */'@/features/spot-results/spot-results-desktop-v4/spot-results-desktop-v4'));
export const SpotTilesDesktopV3 = dynamic(() => import(/* webpackChunkName: "SpotTilesDesktopV3" */'@/features/spot-tiles/spot-tiles-desktop-v3/spot-tiles-desktop-v3'));
export const CtaBannerDesktopV1 = dynamic(() => import(/* webpackChunkName: "CtaBannerDesktopV1" */'@/features/banner/cta-banner-desktop-v1/cta-banner-desktop-v1'));
export const MapPreviewV1 = dynamic(() => import(/* webpackChunkName: "MapPreviewV1" */'@/features/map/map-preview-v1/map-preview-v1-container'));
export const CustomerFeedbackV1Desktop = dynamic(() => import (/* webpackChunkName: "CustomerFeedbackV1" */'@/features/customer-feedback/customer-feedback-v1/customer-feedback-desktop/customer-feedback-v1-desktop'));
export const QuickFilterV1Desktop = dynamic(() => import(/* webpackChunkName: "QuickFilterV1Desktop" */'@/features/filter/quick-filter/quick-filter/quick-filter-container-desktop'));
export const BreadcrumbNavigationV1Desktop = dynamic(() => import(/* webpackChunkName: "BreadcrumbNavigationV1Desktop" */'@/features/breadcrumb-navigation-v1-desktop/breadcrumb-navigation-v1-desktop'));
export const NavigationBarV1Desktop = dynamic(() => import(/* webpackChunkName: "NavigationBarV1Desktop" */'@/features/navigation-bar-v1-desktop/navigation-bar-v1-desktop'));
export const AccommodationTilesV1Desktop = dynamic(() => import (/* webpackChunkName: "AccommodationTilesV1Desktop" */'@/features/accommodation-tiles/accommodation-tiles-v1-desktop/accommodation-tiles-v1-desktop-container'));
export const WishlistButtonV1Desktop = dynamic(() => import(/* webpackChunkName: "WishlistButtonV1Desktop" */'@/features/wishlist/wishlist-button-v1/wishlist-button-desktop-v1/wishlist-button-v1-desktop'));
export const SeaBannerDesktopV1 = dynamic(() => import(/* webpackChunkName: "SeaBannerDesktopV1" */'@/features/banner/sea-banner-v1/sea-banner-v1-desktop/sea-banner-v1-desktop'));
export const TravelFormV1 = dynamic(() => import (/* webpackChunkName: "TravelFormV1" */'@/features/travel-form/travel-form-v1/travel-form-v1-container'));
export const ContactBarV1Desktop = dynamic(() => import (/* webpackChunkName: "ContactBarV1Desktop" */'@/features/contact-bar-v1/contact-bar-desktop/contact-bar-desktop'));
export const ThirdViewHeaderDesktop = dynamic(() => import (/* webpackChunkName: "ThirdViewHeaderDesktop" */'@/features/third-view-header-v1/third-view-header-desktop/third-view-header-desktop'));
export const ThirdViewBookingInfoDesktop = dynamic(() => import (/* webpackChunkName: "ThirdViewBookingInfoDesktop" */'@/features/third-view-booking-info-v1/third-view-booking-info-desktop/third-view-booking-info-desktop'));
export const ThirdViewOfferDesktop = dynamic(() => import (/* webpackChunkName: "ThirdViewOfferDesktop" */'@/features/third-view-offer-v1/third-view-offer-desktop/third-view-offer-desktop'));
export const ThirdViewContactInformationDesktop = dynamic(() => import (/* webpackChunkName: "ThirdViewContactInformationDesktop" */'@/features/third-view-contact-information-v1/third-view-contact-information-desktop'));
export const ThirdViewPaymentInfoV1 = dynamic(() => import(/* webpackChunkName: "ThirdViewPaymentInfoV1" */'@/features/third-view-payment-information-v1/third-view-payment-information-v1'));
export const CheckoutPriceOverviewDesktopV1 = dynamic(() => import(/* webpackChunkName: "CheckoutPriceOverviewDesktopV1" */'@/features/checkout/checkout-price-overview-desktop-v1/checkout-price-overview-desktop-v1'));
export const CheckoutHeaderDesktopV1 = dynamic(() => import(/* webpackChunkName: "CheckoutHeaderDesktopV1" */'@/features/checkout/checkout-header-desktop-v1/checkout-header-desktop-v1'));
export const CartV1Desktop = dynamic(() => import(/* webpackChunkName: "CartV1Desktop" */'@/features/cart/cart-v1/cart-v1-desktop'));
export const RoutesV1Desktop = dynamic(() => import(/* webpackChunkName: "RoutesV1Desktop" */'@/features/routes-v1/routes-v1-desktop/routes-v1-desktop'));
export const CheckoutSummaryV1Desktop = dynamic(() => import(/* webpackChunkName: "CheckoutSummaryV1Desktop" */'@/features/checkout/checkout-summary-v1/checkout-summary-v1-desktop/checkout-summary-v1-desktop'));
export const PieChartV1Desktop = dynamic(() => import (/* webpackChunkName: "PieChartV1" */'@/features/pie-chart-v1/pie-chart-v1'));
export const ThirdViewCancellationV2Desktop = dynamic(() => import(/* webpackChunkName: "ThirdViewCancellationV2Desktop" */'@/features/third-view-cancellation-v2/third-view-cancellation-v2'));
export const ActivityAreaTilesV1Desktop = dynamic(() => import(/* webpackChunkName: "ActivityAreaTilesV1Desktop" */'@/features/activity-area-tiles/activity-area-tiles-v1-desktop/activity-area-tiles-v1-desktop'));
export const ContinueSearchV1Desktop = dynamic(() => import(/* webpackChunkName: "ContinueSearchV1Desktop" */'@/features/continue-search/continue-search-v1-desktop/continue-search-v1-desktop'));
export const ContinueBookingV1Desktop = dynamic(() => import(/* webpackChunkName: "ContinueBookingV1Desktop" */'@/features/continue-booking/continue-booking-v1-desktop/continue-booking-v1-desktop'));
export const SkiSlopeDesktopV1 = dynamic(() => import(/* webpackChunkName: "SkiSlopeDesktopV1" */'@/features/ski-slope/ski-slope-desktop-v1/ski-slope-desktop-v1'))
export const PlaceHeaderV1Desktop = dynamic(() => import(/* webpackChunkName: "PlaceHeaderV1Desktop" */'@/features/place-header/place-header-v1-desktop/place-header-v1-desktop'))
export const HighlightHeaderV1Desktop = dynamic(() => import(/* webpackChunkName: "HighlightHeaderV1Desktop" */'@/features/highlight-header/highlight-header-v1-desktop/highlight-header-v1-desktop'))
export const ActivitySpotSwiperV1Desktop = dynamic(() => import(/* webpackChunkName: "ActivitySpotSwiperV1Desktop" */'@/features/activity-spot-swiper-v1/activity-spot-swiper-v1-desktop'))
export const HighlightSwiperV1Desktop = dynamic(() => import(/* webpackChunkName: "HighlightSwiperV1Desktop" */'@/features/highlight-swiper-v1/highlight-swiper-v1-desktop/highlight-swiper-v1-desktop'))
export const OpeningHoursV1Desktop = dynamic(() => import(/* webpackChunkName: "OpeningHoursV1Desktop" */'@/features/opening-hours-v1/opening-hours-v1'))
export const CommunityTilesV1Desktop = dynamic(() => import(/* webpackChunkName: "CommunityTilesV1Desktop" */'@/features/community-tiles/community-tiles-v1-desktop/community-tiles-v1-desktop'))
export const CityTilesV1Desktop = dynamic(() => import(/* webpackChunkName: "CityTilesV1" */'@/features/city-tiles-v1/city-tiles-v1-desktop'))
export const ThirdViewELearningInfoV1 = dynamic(() => import(/* webpackChunkName: "ThirdViewELearningInfoV1" */'@/features/third-view-e-learning-information-v1/third-view-e-learning-information-v1'));
